@import url(https://fonts.googleapis.com/css2?family=Fira+Code&display=swap);
@font-face {
  font-family: "Proxima Nova Bold";
  src: local('Proxima Nova Bold'), url("/static/media/Proxima Nova Alt Bold.da0d3d7c.otf") format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: "Proxima Nova Semibold";
  src: local('Proxima Nova Semibold'), url("/static/media/Proxima Nova Semibold.b36cba87.ttf") format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: local('Proxima Nova'), url("/static/media/Proxima Nova Regular.8181ca4b.ttf") format('truetype');
  font-weight: lighter;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --theme-button-text: rgb(255, 255, 255);
  --light: rgb(219, 215, 212);
  --semidark: rgb(80, 80, 80);
  --dark: rgb(36, 36, 36);
  --accent-bright: rgb(255, 69, 0);
  --accent-bright-subtle: rgb(225, 62, 5);
  --accent-complement: rgb(8, 30, 103);
  --accent-complement-subtle: rgb(1, 1, 90);
  --accent-bright-complement: rgb(0, 187, 255);
  --transparent: rgba(0, 0, 0, 0);

  --cucumber-water: linear-gradient(90deg, rgba(53, 61, 54, 0.2) 0%, rgba(52, 69, 94, 0.2) 100%);

  --linear-gradient:
    linear-gradient(127deg, rgba(57, 104, 175, 0.2), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(45deg, rgba(247, 76, 232, 0.1), rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(210deg, rgba(0, 255, 200, 0.2), rgba(0, 0, 255, 0) 70.71%);

  --darken-overlay: linear-gradient(to top right, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 0%);
  --lighten-overlay: linear-gradient(to top right, rgba(179, 179, 179, 0.1) 0%, rgba(119, 119, 119, 0.2) 100%);

  --bg-dark: url(/static/media/layered-waves-haikei.0d600221.svg);
  --bg-light: url(/static/media/layered-waves-haikei-blue-red.44172255.svg);

  font-family: 'Proxima Nova', 'Trebuchet MS', Helvetica, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

#root {
  height: 100%;
}

*,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  height: 100vh;
}

h1 {
  font-size: 5vh;
  font-family: 'Proxima Nova', 'Trebuchet MS', Helvetica, sans-serif;
}

h3 {
  font-size: 3vh;
  font-family: 'Proxima Nova', 'Trebuchet MS', Helvetica, sans-serif;
}

.light {
  --theme-page-background: var(--light);
  --theme-page-text: var(--light);
  --theme-button-color: var(--accent-complement);
  --theme-hover-color: var(--accent-complement-subtle);
  --theme-same: var(--light);
  --theme-diff: var(--dark);
  --theme-mid: var(--semidark);
  --theme-link-color: var(--accent-bright-complement);
  --overlay-filter: var(--lighten-overlay);

  /* background: repeat var(--bg-light); */
  /* background-size: 100px auto; */
  background-image: url(/static/media/layered-waves-haikei-blue-red.44172255.svg);
  background-image: var(--bg-light);
  background-size: cover;

}

.dark {
  --theme-page-background: var(--dark);
  --theme-page-text: var(--light);
  --theme-button-color: var(--accent-bright);
  --theme-hover-color: var(--accent-bright-subtle);
  --theme-same: var(--dark);
  --theme-diff: var(--light);
  --theme-mid: var(--semidark);
  --theme-link-color: var(--accent-bright);
  --overlay-filter: var(--darken-overlay);
  /* background-image:
    var(--cucumber-water)
    var(--bg-dark);
  background-repeat: repeat;
  background-size: 200px auto; */
  background-image: url(/static/media/layered-waves-haikei.0d600221.svg);
  background-image: var(--bg-dark);
  background-size: cover;
}

a,
a:visited {
  color: var(--theme-link-color);
  text-decoration: none;
}

a:hover {
  filter: hue-rotate(180deg);
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 2em;
}

.badges {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: scale(2);
  transform-origin: center;
}

.row {
  flex-direction: row;
  margin: .25em;
  flex-wrap: wrap;
  justify-content: center;
}

a {
  margin: 0.2em;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .App {
    overflow: hidden;
  }

  .main {
    flex: 1 1;
    width: 90vw;
  }

  .header {
    font-size: 1.75vh;
  }

  .threedp {
    height: 40vh !important;
  }

  .badges {
    transform: scale(1.25);
  }
}

/* Small devices (portrait tablets and large phones, 600 and up) */
@media only screen and (min-width: 600px) and (max-height: 800px) {
  .App {
    overflow: hidden;
  }

  .main {
    flex: 1 1;
    justify-content: flex-start;
    align-items: center;
    transform: scale(1);
  }

  .badges {
    transform: scale(1.75);
  }
}


/* TODO: broken on safari.. add vendor specific prefixes and see:
- https://css-tricks.com/snippets/css/keyframe-animation-syntax/
- https://developer.apple.com/library/archive/documentation/InternetWeb/Conceptual/SafariVisualEffectsProgGuide/AnimatingWithKeyframes/AnimatingWithKeyframes.html#//apple_ref/doc/uid/TP40008032-CH14-SW5 ?? */


svg {
    stroke: var(--accent-bright);
    stroke-width: 4;
    fill: transparent;
    transform: translate(-5px,-2px);

}
.threedp {
    position: relative;
    display: flex;
    height: 350px;
    margin: auto;

    justify-content: center;
    align-items: center;
}

.box {
    position: relative;
    width: 200px;
    height: 200px;
    border: 0.65em solid var(--theme-diff);
    border-radius: 10%;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    z-index: 100;
    transform: translate(-5px, 0px);
}

.spool {
    position: absolute;
    height: 125px;
    width: 30px;
    background-clip: content-box;
    border-top: 5px solid var(--transparent);
    border-left: 5px solid var(--theme-diff);
    border-right: 5px solid var(--theme-diff);
    border-bottom: 5px solid var(--transparent);
    background-color: var(--accent-bright);
    transform: translate(115px, 5px);
    z-index: auto;
    z-index: initial;
}

.inner {
    width: inherit;
    height: 15px;
    position: absolute;
    background-color: transparent;
    transform: translate(0, 22.5px);
}

.bars {
    width: 400px;
    height: 15px;
    position: absolute;
    background-color: transparent;
    border-top: 5px solid var(--theme-diff);
    border-bottom: 5px solid var(--theme-diff);
    border-left: 0px;
    border-right: 0px;
    /* animation: raise 8s steps(8, end) reverse; */
    animation: move linear 8000ms;
    animation-direction: reverse;
    offset-path: path("M 102 -158 V -148.4 H 116.4 Q 121.2 -143.6 116.4 -138.8 H 82.8 Q 78 -134 82.8 -129.2 H 154.8 Q 159.6 -124.4 154.8 -119.6 H 44.4 Q 39.6 -114.8 44.4 -110 H 154.8 Q 159.6 -105.2 154.8 -100.4 H 44.4 Q 39.6 -95.6 44.4 -90.8 H 154.8 Q 159.6 -86 154.8 -81.2 H 42");
    offset-rotate: 0deg;
    z-index: -10;
}
/*
@-webkit-keyframes raise {
    from {
        transform: translate(0, 25px);
    }
    to {
        transform: translate(0, 90px);
    }
}

@keyframes raise {
    from {
        transform: translate(0, 25px);
    }
    to {
        transform: translate(0, 90px);
    }
} */

.bed {
    width: 150px;
    position: absolute;
    border-top: 10px solid var(--theme-diff);
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transform: translate(15px, 165px);

}

/* hotend case */
.hotend {
    width: 45px;
    height: 45px;
    background-color: var(--theme-mid);
    position: absolute;
    /* transform: translate(78px, -10px); */
    animation: move linear 8000ms;
    animation-name: move;
    animation-timing-function: linear;
    animation-duration: 8000ms;
    animation-direction: reverse;
    offset-path: path("M 102 -158 V -140.4 H 116.4 Q 121.2 -143.6 116.4 -138.8 H 82.8 Q 78 -134 82.8 -129.2 H 154.8 Q 159.6 -124.4 154.8 -119.6 H 44.4 Q 39.6 -114.8 44.4 -110 H 154.8 Q 159.6 -105.2 154.8 -100.4 H 44.4 Q 39.6 -95.6 44.4 -90.8 H 154.8 Q 159.6 -86 154.8 -81.2 H 42");
    offset-rotate: 0deg;
    transform: translate(-6.5px,-7px)
}

@keyframes move {
  100% {
    offset-distance: 100%;
  }
}

/* hotend fan */
.hotend:before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    background-color: var(--theme-mid);
    border: 2.5px dotted var(--light);
    filter: contrast(55%);
    border-radius: 50%;
    transform: translate(5px, 5px);
}

/* hotend nozzle */
.hotend:after {
    content: '';
    width: 30px;
    height: 20px;
    position: absolute;
    background-color: var(--theme-mid);
    filter: contrast(25%);
    -webkit-clip-path: polygon(
        0% 20%, 15% 20%,
        15% 0%, 85% 0%,
        85% 25%, 100% 25%,
        100% 60%, 80% 65%,
        65% 90%, 35% 90%,
        20% 65% , 0% 60%);
    clip-path: polygon(
        0% 20%, 15% 20%,
        15% 0%, 85% 0%,
        85% 25%, 100% 25%,
        100% 60%, 80% 65%,
        65% 90%, 35% 90%,
        20% 65% , 0% 60%);
    transform: translate(7.5px, 45px);
}

path {
  stroke-dasharray: 634.277;
  /* stroke-dashoffset: 634.277; */
  animation: draw linear 8000ms forwards;
  animation-direction: forwards;
}

@keyframes draw {
  from {
      stroke-dashoffset: 634.277;
  }
  to {
      stroke-dashoffset: 1268.554;
  }
}

ul {
  padding: 2em;
}

li {
  list-style: square;
}

.App {
  height: 100%;
  width: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  background-color: var(--theme-page-background);
  color: var(--theme-page-text);

}

.container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-self: flex-start;
  justify-content: center;
  padding: 2em 2em 20em 2em;
  margin: 2em;
  background: var(--linear-gradient),
    var(--overlay-filter);
  background-blend-mode: darken;
}


.container>* {
  flex-basis: 100%;
  color: var(--theme-button-text);
}

.toggle-theme {
  position: absolute;
  top: 0;
  right: 0;

  padding: 0.75em;
  margin: 1.5em;
  border-radius: .5rem;
  text-align: center;
  background-color: var(--theme-button-color);
  color: var(--theme-button-text);
  font-size: .75em;
  border: 0 solid transparent;
  z-index: 1000;
}

.toggle-theme:hover {
  background-color: var(--theme-hover-color);
}

.toggle-theme :focus {
  outline: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  h1 {
    font-size: calc(2em + 2vw) !important;
    font-family: 'Proxima Nova';
  }

  h3 {
    font-size: calc(1em + 1vw) !important;
    font-family: 'Proxima Nova';
  }

}

/* Small devices (portrait tablets and large phones, 600 and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}



/* TODO: broken on safari.. add vendor specific prefixes and see:
- https://css-tricks.com/snippets/css/keyframe-animation-syntax/
- https://developer.apple.com/library/archive/documentation/InternetWeb/Conceptual/SafariVisualEffectsProgGuide/AnimatingWithKeyframes/AnimatingWithKeyframes.html#//apple_ref/doc/uid/TP40008032-CH14-SW5 ?? */


svg {
    stroke: var(--accent-bright);
    stroke-width: 4;
    fill: transparent;
    transform: translate(-5px,-2px);

}
.threedp {
    position: relative;
    display: flex;
    height: 350px;
    margin: auto;

    justify-content: center;
    align-items: center;
}

.box {
    position: relative;
    width: 200px;
    height: 200px;
    border: 0.65em solid var(--theme-diff);
    border-radius: 10%;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    z-index: 100;
    transform: translate(-5px, 0px);
}

.spool {
    position: absolute;
    height: 125px;
    width: 30px;
    background-clip: content-box;
    border-top: 5px solid var(--transparent);
    border-left: 5px solid var(--theme-diff);
    border-right: 5px solid var(--theme-diff);
    border-bottom: 5px solid var(--transparent);
    background-color: var(--accent-bright);
    transform: translate(115px, 5px);
    z-index: initial;
}

.inner {
    width: inherit;
    height: 15px;
    position: absolute;
    background-color: transparent;
    transform: translate(0, 22.5px);
}

.bars {
    width: 400px;
    height: 15px;
    position: absolute;
    background-color: transparent;
    border-top: 5px solid var(--theme-diff);
    border-bottom: 5px solid var(--theme-diff);
    border-left: 0px;
    border-right: 0px;
    /* animation: raise 8s steps(8, end) reverse; */
    -webkit-animation: move linear 8000ms;
            animation: move linear 8000ms;
    animation-direction: reverse;
    offset-path: path("M 102 -158 V -148.4 H 116.4 Q 121.2 -143.6 116.4 -138.8 H 82.8 Q 78 -134 82.8 -129.2 H 154.8 Q 159.6 -124.4 154.8 -119.6 H 44.4 Q 39.6 -114.8 44.4 -110 H 154.8 Q 159.6 -105.2 154.8 -100.4 H 44.4 Q 39.6 -95.6 44.4 -90.8 H 154.8 Q 159.6 -86 154.8 -81.2 H 42");
    offset-rotate: 0deg;
    z-index: -10;
}
/*
@-webkit-keyframes raise {
    from {
        transform: translate(0, 25px);
    }
    to {
        transform: translate(0, 90px);
    }
}

@keyframes raise {
    from {
        transform: translate(0, 25px);
    }
    to {
        transform: translate(0, 90px);
    }
} */

.bed {
    width: 150px;
    position: absolute;
    border-top: 10px solid var(--theme-diff);
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transform: translate(15px, 165px);

}

/* hotend case */
.hotend {
    width: 45px;
    height: 45px;
    background-color: var(--theme-mid);
    position: absolute;
    /* transform: translate(78px, -10px); */
    -moz-animation: move linear 8000ms;
         animation: move linear 8000ms;
    -webkit-animation-name: move;
            animation-name: move;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-duration: 8000ms;
            animation-duration: 8000ms;
    -webkit-animation-direction: reverse;
            animation-direction: reverse;
    offset-path: path("M 102 -158 V -140.4 H 116.4 Q 121.2 -143.6 116.4 -138.8 H 82.8 Q 78 -134 82.8 -129.2 H 154.8 Q 159.6 -124.4 154.8 -119.6 H 44.4 Q 39.6 -114.8 44.4 -110 H 154.8 Q 159.6 -105.2 154.8 -100.4 H 44.4 Q 39.6 -95.6 44.4 -90.8 H 154.8 Q 159.6 -86 154.8 -81.2 H 42");
    offset-rotate: 0deg;
    transform: translate(-6.5px,-7px)
}


@-webkit-keyframes move {
  0% {
    offset-distance: 0%;
  }
  100% {
    offset-distance: 100%;
  }
}

@keyframes move {
  100% {
    offset-distance: 100%;
  }
}

/* hotend fan */
.hotend:before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    background-color: var(--theme-mid);
    border: 2.5px dotted var(--light);
    filter: contrast(55%);
    border-radius: 50%;
    transform: translate(5px, 5px);
}

/* hotend nozzle */
.hotend:after {
    content: '';
    width: 30px;
    height: 20px;
    position: absolute;
    background-color: var(--theme-mid);
    filter: contrast(25%);
    -webkit-clip-path: polygon(
        0% 20%, 15% 20%,
        15% 0%, 85% 0%,
        85% 25%, 100% 25%,
        100% 60%, 80% 65%,
        65% 90%, 35% 90%,
        20% 65% , 0% 60%);
    clip-path: polygon(
        0% 20%, 15% 20%,
        15% 0%, 85% 0%,
        85% 25%, 100% 25%,
        100% 60%, 80% 65%,
        65% 90%, 35% 90%,
        20% 65% , 0% 60%);
    transform: translate(7.5px, 45px);
}

path {
  stroke-dasharray: 634.277;
  /* stroke-dashoffset: 634.277; */
  -webkit-animation: draw linear 8000ms forwards;
          animation: draw linear 8000ms forwards;
  -webkit-animation-direction: forwards;
          animation-direction: forwards;
}

@-webkit-keyframes draw {
  from {
      stroke-dashoffset: 634.277;
  }
  to {
      stroke-dashoffset: 1268.554;
  }
}

@keyframes draw {
  from {
      stroke-dashoffset: 634.277;
  }
  to {
      stroke-dashoffset: 1268.554;
  }
}

.App {
  height: 100%;
  width: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  background-color: var(--theme-page-background);
  color: var(--theme-page-text);

}

.container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-self: flex-start;
  justify-content: center;
  padding: 2em 2em 20em 2em;
  margin: 2em;
  background: var(--linear-gradient),
    var(--overlay-filter);
  background-blend-mode: darken;
}


.container>* {
  flex-basis: 100%;
  color: var(--theme-button-text);
}

.toggle-theme {
  position: absolute;
  top: 0;
  right: 0;

  padding: 0.75em;
  margin: 1.5em;
  border-radius: .5rem;
  text-align: center;
  background-color: var(--theme-button-color);
  color: var(--theme-button-text);
  font-size: .75em;
  border: 0 solid transparent;
  z-index: 1000;
}

.toggle-theme:hover {
  background-color: var(--theme-hover-color);
}

.toggle-theme :focus {
  outline: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  h1 {
    font-size: calc(2em + 2vw) !important;
    font-family: 'Proxima Nova';
  }

  h3 {
    font-size: calc(1em + 1vw) !important;
    font-family: 'Proxima Nova';
  }

}

/* Small devices (portrait tablets and large phones, 600 and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

@font-face {
  font-family: "Proxima Nova Bold";
  src: local('Proxima Nova Bold'), url('./font/Proxima\ Nova\ Alt\ Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: "Proxima Nova Semibold";
  src: local('Proxima Nova Semibold'), url('./font/Proxima\ Nova\ Semibold.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: local('Proxima Nova'), url('./font/Proxima\ Nova\ Regular.ttf') format('truetype');
  font-weight: lighter;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

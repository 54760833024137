:root {
  --theme-button-text: rgb(255, 255, 255);
  --light: rgb(219, 215, 212);
  --semidark: rgb(80, 80, 80);
  --dark: rgb(36, 36, 36);
  --accent-bright: rgb(255, 69, 0);
  --accent-bright-subtle: rgb(225, 62, 5);
  --accent-complement: rgb(8, 30, 103);
  --accent-complement-subtle: rgb(1, 1, 90);
  --accent-bright-complement: rgb(0, 187, 255);
  --transparent: rgba(0, 0, 0, 0);

  --cucumber-water: linear-gradient(90deg, rgba(53, 61, 54, 0.2) 0%, rgba(52, 69, 94, 0.2) 100%);

  --linear-gradient:
    linear-gradient(127deg, rgba(57, 104, 175, 0.2), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(45deg, rgba(247, 76, 232, 0.1), rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(210deg, rgba(0, 255, 200, 0.2), rgba(0, 0, 255, 0) 70.71%);

  --darken-overlay: linear-gradient(to top right, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 0%);
  --lighten-overlay: linear-gradient(to top right, rgba(179, 179, 179, 0.1) 0%, rgba(119, 119, 119, 0.2) 100%);

  --bg-dark: url('./layered-waves-haikei.svg');
  --bg-light: url('./layered-waves-haikei-blue-red.svg');

  font-family: 'Proxima Nova', 'Trebuchet MS', Helvetica, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

#root {
  height: 100%;
}

*,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  height: 100vh;
}

h1 {
  font-size: 5vh;
  font-family: 'Proxima Nova', 'Trebuchet MS', Helvetica, sans-serif;
}

h3 {
  font-size: 3vh;
  font-family: 'Proxima Nova', 'Trebuchet MS', Helvetica, sans-serif;
}

.light {
  --theme-page-background: var(--light);
  --theme-page-text: var(--light);
  --theme-button-color: var(--accent-complement);
  --theme-hover-color: var(--accent-complement-subtle);
  --theme-same: var(--light);
  --theme-diff: var(--dark);
  --theme-mid: var(--semidark);
  --theme-link-color: var(--accent-bright-complement);
  --overlay-filter: var(--lighten-overlay);

  /* background: repeat var(--bg-light); */
  /* background-size: 100px auto; */
  background-image: var(--bg-light);
  background-size: cover;

}

.dark {
  --theme-page-background: var(--dark);
  --theme-page-text: var(--light);
  --theme-button-color: var(--accent-bright);
  --theme-hover-color: var(--accent-bright-subtle);
  --theme-same: var(--dark);
  --theme-diff: var(--light);
  --theme-mid: var(--semidark);
  --theme-link-color: var(--accent-bright);
  --overlay-filter: var(--darken-overlay);
  /* background-image:
    var(--cucumber-water)
    var(--bg-dark);
  background-repeat: repeat;
  background-size: 200px auto; */
  background-image: var(--bg-dark);
  background-size: cover;
}

a,
a:visited {
  color: var(--theme-link-color);
  text-decoration: none;
}

a:hover {
  filter: hue-rotate(180deg);
}
